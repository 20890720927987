<template>
   <v-app>
      <v-main>
         <v-form ref="form" @submit.prevent="login" class="row justify-center align-center">
            <v-col lg="4" md="6" sm="8" xl="3">
               <v-card dark color="rgba(0, 0, 0, 0.6)">
                  <v-card-title>Selamat Datang</v-card-title>
                  <v-card-text class="py-0">{{change_pwd ? 'Ganti password': message}}</v-card-text>
                  <template v-if="change_pwd">
                     <v-card-text class="px-10">
                        <v-text-field append-icon="mdi-account-key" label="Password Baru" type="password" v-model="fm.password" :rules="pwdRules" />
                        <v-text-field append-icon="mdi-key" label="Password Konfirmasi" type="password" v-model="fm.password2" :rules="[checkPwd]" />
                        <div class="my-4 text-center">
                           <v-btn outlined rounded width="120px" color="primary" type="submit" :loading="loading">submit</v-btn>
                        </div>
                     </v-card-text>
                  </template>
                  <template v-else>
                     <v-card-text class="px-10">
                        <v-text-field append-icon="mdi-account" label="Login" type="text" v-model="fm.username" :rules="usrRules" />
                        <v-text-field append-icon="mdi-account-key" label="Password" type="password" v-model="fm.password" :rules="pwdRules" />
                     </v-card-text>
                     <v-card-actions class="justify-center pb-8">
                        <v-btn outlined rounded width="120px" color="primary" type="submit" :loading="loading">Login</v-btn>
                     </v-card-actions>
                  </template>
               </v-card>
            </v-col>
         </v-form>
      </v-main>
      <alert />
   </v-app>
</template>

<script>
import api from '../api';
import Alert from '../components/Alert.vue';
export default {
   components: { Alert },
   data: () => ({
      change_pwd: false,
      fm: {},
      usrRules: [v => !!v || 'Username harus diisi' ],
      pwdRules: [v => !!v || 'Password harus diisi' ],
      loading: false,
      token: null,
      message: 'silahkan login untuk melanjutkan'
   }),
   mounted() {
      this.fm.sid = this.$store.state.auth.sid;
   },
   methods: {
      login() {
         if(!this.$refs.form.validate()) return;
         this.loading = true;
         if(this.change_pwd) {
            this.fm.token = this.token;
            api.edit('user', this.fm).then(({msg,status}) => {
               if(status === 1) {
                  this.$alert({title: 'Sukses', text: msg, type: 'success'});
                  this.change_pwd = false;
                  this.$refs.form.reset();
               } else this.$alert({title: 'Ooops!', text: msg, type: 'warning'});
            }).finally(() => { this.loading = false });
         } else {
            api.login(this.fm).then(({status, msg, ...data}) => {
               if(status === 1) {
                  // this.$alert({ type: 'success', title: msg, showConfirmButton: false, timer: 1500 });
                  this.$store.commit('auth/set', data);
                  this.$router.push('/sale');
               } else {
                  this.$alert({title: 'Ooops!', text: msg, type: 'warning'});
                  this.fm.password = '';
                  if(status === 4) {
                     this.change_pwd = true;
                     this.$refs.form.reset();
                     this.token = data.token;
                  }
               }
            },
            () => {
               this.$alert('Ooops!', 'Terjadi kesalahan', 'error');
            }).finally(() => { this.loading = false });
         }
      },
      checkPwd(v) {
         return v === this.fm.password || 'Kedua password tidak sama';
      },
   }
}
</script>
<style lang="css" scoped>
#app::before {
   content: '';
   position: fixed;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   background: url("../assets/bg.jpeg") no-repeat center center;
   background-size: cover;
   will-change: transform;
   /* z-index: -1; */
}
form {height: 100%;}
</style>